<template>
  <v-sheet
    elevation="3"
    rounded
    class="mb-4"
  >
    <v-container
      fluid
    >
      <v-row
        dense
      >
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <v-select
            v-model="filters.provider"
            clearable
            outlined
            dense
            prepend-inner-icon="mdi-database-marker-outline"
            hide-details
            item-text="name"
            item-value="id"
            :items="providerValues"
            :label="$t('views.synchro_lines.filters.provider')"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <v-select
            v-model="filters['store[]']"
            clearable
            outlined
            dense
            item-text="name"
            item-value="id"
            prepend-inner-icon="mdi-store-outline"
            hide-details
            :items="storeValues"
            :label="$t('views.synchro_lines.filters.store_code')"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <v-text-field
            v-model="filters.query"
            hide-details
            clearable
            outlined
            dense
            prepend-inner-icon="mdi-magnify"
            :label="$t('views.synchro_lines.filters.query')"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <v-select
            v-model="filters.import_status"
            hide-details
            clearable
            outlined
            dense
            item-text="label"
            item-value="value"
            prepend-inner-icon="mdi-state-machine"
            :items="importStatusValues"
            :label="$t('views.synchro_lines.filters.import_status')"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <v-select
            v-model="filters['category[]']"
            hide-details
            clearable
            outlined
            dense
            item-text="name"
            item-value="id"
            multiple
            prepend-inner-icon="mdi-file-tree-outline"
            :items="categoryValues"
            :label="$t('views.synchro_lines.filters.category')"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <v-select
            v-model="filters['brand[]']"
            hide-details
            clearable
            outlined
            dense
            item-text="name"
            item-value="id"
            multiple
            prepend-inner-icon="mdi-shopping-outline"
            :items="brandValues"
            :label="$t('views.synchro_lines.filters.brand')"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <v-select
            v-model="filters.condition"
            hide-details
            clearable
            outlined
            dense
            item-text="name"
            item-value="id"
            prepend-inner-icon="mdi-check"
            :items="conditionValues"
            :label="$t('views.synchro_lines.filters.condition')"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <v-text-field
            v-model="filters['price[min]']"
            hide-details
            clearable
            outlined
            dense
            prepend-inner-icon="mdi-cash"
            :label="$t('views.synchro_lines.filters.price_min')"
            type="number"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <v-text-field
            v-model="filters['price[max]']"
            hide-details
            clearable
            outlined
            dense
            prepend-inner-icon="mdi-cash"
            :label="$t('views.synchro_lines.filters.price_max')"
            type="number"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <v-switch
            v-model="filters.stockOnly"
            hide-details
            dense
            class="ml-2 mt-1"
            :label="$t('views.synchro_lines.filters.stock_only')"
          />
        </v-col>
        <v-spacer />
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <v-btn
            class="white--text"
            color="blue darken-3"
            @click="search"
          >
            {{ $t('views.synchro_lines.forms.input.search') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SynchroLinesFilters',
  props: {
    initialFilters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      filters: {
        ...{
          provider: null,
          'store[]': null,
          query: null,
          'category[]': null,
          import_status: null,
          'brand[]': null,
          condition: null,
          'price[min]': null,
          'price[max]': null,
          stockOnly: false,
        },
        ...this.initialFilters,
      },
      classifications: { },
      providerValues: [],
      storeValues: [],
      importStatusValues: [
        {
          value: 'none',
          label: this.$i18n.t('views.synchro_lines.enum.import_status.none'),
        },
        {
          value: 'pending',
          label: this.$i18n.t('views.synchro_lines.enum.import_status.pending'),
        },
        {
          value: 'done',
          label: this.$i18n.t('views.synchro_lines.enum.import_status.done'),
        },
        {
          value: 'deleted',
          label: this.$i18n.t('views.synchro_lines.enum.import_status.deleted'),
        },
        {
          value: 'error',
          label: this.$i18n.t('views.synchro_lines.enum.import_status.error'),
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['taxonomiesBrands', 'taxonomiesStates', 'categories']),
    categoryValues: function () {
      if (!this.classifications.category) {
        return []
      }

      return this.categories
        .filter(v => this.classifications.category[v.id])
        .map(v => ({
          id: this.classifications.category[v.id].external_references[0],
          name: v.long_names['fr'],
        }))
    },
    brandValues: function () {
      if (!this.classifications.taxonomy) {
        return []
      }

      return this.taxonomiesBrands
        .filter(v => this.classifications.taxonomy[v.id])
        .map(v => ({
          id: this.classifications.taxonomy[v.id].external_references[0],
          name: v.name,
        }))
    },
    conditionValues: function () {
      if (!this.classifications.taxonomy) {
        return []
      }

      return this.taxonomiesStates
        .filter(v => this.classifications.taxonomy[v.id])
        .map(v => ({
          id: this.classifications.taxonomy[v.id].external_references[0],
          name: v.name,
        }))
    },
  },
  watch: {
    'filters.provider': async function (newProvider, oldProvider) {
      await this.changeProvider(this.filters.provider, oldProvider !== null)
    },
  },
  async created() {
    const responseProvider = await this.$axios.get('external_catalog/providers')
    this.providerValues = responseProvider.data['hydra:member'].map(
      provider => {
        return {
          id: provider.id,
          name: provider.name,
        }
      }
    )

    if (this.filters.provider) {
      this.changeProvider(this.filters.provider)
    }
  },
  methods: {
    async changeProvider(provider, resetFilters) {
      if (resetFilters) {
        this.filters = { provider }
      }
      await this.fetchProvider()
    },
    async fetchProvider() {
      const response = await this.$axios.get('external_catalog/providers/' + this.filters.provider)

      const summary = response.data['synchro_line_summary']

      const indexedClassifications = {}
      for (const classification of summary.classifications) {
        if (!indexedClassifications[classification.type]) {
          indexedClassifications[classification.type] = {}
        }
        indexedClassifications[classification.type][classification.internal_id] = classification
      }
      this.classifications = indexedClassifications
      this.storeValues = summary.referenced_stores.map(v => ({ id: v, name: v }))
    },
    search() {
      this.$emit('onFiltersChanged',  { ...this.filters })
    },
  },
}
</script>
